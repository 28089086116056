<template>
	<div class="p-3 py-4" style="max-height: 100vh; overflow-y: auto;height: 100%;min-height: 400px;background: none" @click.self="$emit('close')">
		<div class="close-btn" v-on:click="$emit('close')">
			<font-awesome-icon class="m-auto" icon="times" />
		</div>


		<div class="">
			<h4>{{ $t('routePlanModal.select') }}</h4>

			<div class="route-plans-con">
				<div class="route-plans w-100">
					<route-plan-card :route-plan="rp" v-for="rp in routePlans"  :key="rp.id" :small="true" :disable-actions="true" @click="select(rp)" />
					<Skeleton v-if="loading" :count="4" width="100%"  class="skeletons" height="103px" />
					<ServerError v-if="error && !loading" @retry="loadData()" />
					<div id="masonry" v-if="!loading && !error"></div>
				</div>
				<div v-if="routePlans.length === 0 && !loading">
					<h5>{{ $t('routePlan.emptyList') }}</h5>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { getPlaneIcon } from "@/plugins/planeUtils";
import Fuse from 'fuse.js';
import PlaneCard from "@/components/plane/PlaneCard.vue";
import AutoComplete from "primevue/autocomplete";
import { calcDistance } from "@/plugins/calculator";
import { LOCAL_STORAGE_SERVICE, READ_JSON_FILE, STORAGE_KEY } from "@/plugins/utils";
import { mapGetters } from "vuex";
import ServerError from "@/components/ServerError.vue";
import RoutePlanCard from "@/components/routePlan/RoutePlanCard.vue";

const PRE_UID = 'rp_';
export default {
	name: 'RoutePlanSelectModal',
	components: { RoutePlanCard, ServerError },
	emits: ['close'],
	data() {
		return {
			/** @type {RoutePlan[]} */
			routePlans: [],
			loading: true,
			error: false,
		}
	},
	watch: {

	},
	methods: {
		select(rp) {
			this.$emit('close', rp);
		},
		async loadData() {
			this.loading = true;
			this.routePlans = [];

			const storedRoutePlans = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.ROUTE_PLANE, {});
			this.routePlans = ((await this.$rest.getRoutePlansForUser()) || []).map(routePlan => {
				routePlan.stored = false;
				const storedKey = Object.keys(storedRoutePlans).find(uid => uid === PRE_UID + routePlan.id);
				if (storedKey) {
					routePlan.stored = true;
				}
				return routePlan;
			});

			this.loading = false;
		},
	},
	async mounted() {
		await this.loadData();
	}
}
</script>

<style scoped>
.add-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	background: white;
	border-radius: 0.6rem;
}


.search-input input {
	border: none;
	background-color: transparent;
	width: 100%;
	padding: 0.5rem 1rem;
}


@media screen and (max-width: 500px){
	h4 {
		font-size: 1.2rem;
	}
}


.close-btn {
	position: absolute;
	top: 32px;
	right: 22px;
	z-index: 1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

h4 {
	color: black;
	padding: 1rem;
	margin: 0;
}


.route-plans-con {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0 auto;
}
.route-plans-con .route-plans {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5rem;
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
	top: 0;
	position: relative;
}


@media screen and (max-width: 500px){
	.route-plans-con .route-plans {
		flex-direction: column;
	}
}

.skeletons {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	max-width: 1248px;
}

</style>

<style>
.skeletons .pu-skeleton {
	width: 100%;
	border-radius: 1rem;
}

@media screen and (max-width: 800px) {
	.skeletons {
		align-items: center;
	}
	.skeletons .pu-skeleton {
		height: 246px !important;
		max-width: 406px;
	}
}
</style>

