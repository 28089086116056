<template>
	<div class="btn-btn" ref="btn">
		<button :class="btnClass" @click.stop="toggle">
			<slot name="button" >
					<font-awesome-icon icon="ellipsis-h" />
			</slot>
		</button>
		<div class="invisible">
			<div class="dropdown-btn-menu" ref="menu">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DropdownButton',
	props: {
		btnClass: String,
	},
	data() {
		return {
			show: false,
			id: Math.random().toString(36).substring(7),
		}
	},
	methods: {
		positionMenu() {
			const menu = document.getElementById(this.id)
			if(!menu) return;

			const { left, top, height: bh, width: bw } = this.$refs.btn.getBoundingClientRect();
			const { innerHeight, innerWidth } = window;
			const { width: mw, height: mh } = menu.getBoundingClientRect();

			let x = left;
			let y = top;

			if (x + mw > innerWidth) {
				x = left - mw + bw;
			}

			if (y + bh + mh > innerHeight) {
				y = top- mh - 10;
			} else {
				y = top + bh + 5;
			}


			menu.style.top = y + 'px';
			menu.style.left = x + 'px';
		},
		toggle() {
			this.show = !this.show;

			document.querySelectorAll('.dropdown-btn-menu').forEach(e => e.remove())

			if (this.show) {
				const menu = this.$refs.menu;
				menu.id = this.id;
				document.querySelector('body').appendChild(menu);
				this.positionMenu()
			}
		},
		outsideClick(e) {
			this.close()
		},
		close(){
			this.show = false;
			document.querySelectorAll('.dropdown-btn-menu').forEach(e => e.remove())
		}
	},
	mounted() {
		document.addEventListener('click', this.outsideClick)

		window.addEventListener('resize', this.positionMenu)
		document.querySelector(".mainArea").addEventListener('scroll', this.positionMenu)

	},
	beforeDestroy() {
		document.removeEventListener('click', this.positionMenu)
		window.removeEventListener('resize', this.positionMenu)
		document.querySelector(".mainArea").removeEventListener('scroll', this.positionMenu)
	}
}
</script>

<style>

.dropdown-btn-menu {
	display: block;
	position: absolute;
	min-width: 160px;
	z-index: 1;
	background-color: #fff;
	padding: 0.5rem;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
}


</style>
