<template>
	<div class="d-flex map-row">
		<modal :name="'airSpaceInfo'" :adaptive="true" :height="'auto'">
			<div class="m-3">
				<div class="row">
					<div class="col-10 text-left">
						<h4>{{ $t('flyingView.airspaceInfo') }}</h4>
					</div>
					<div class="col-2 text-right">
						<a v-on:click="hideAirSpaceInfo()" href="javascript:void(0)" class="btn btn-warning text-center">
							<font-awesome-icon class="m-auto" icon="times" />
						</a>
					</div>
				</div>
				<div class="row" v-if="airSpaceClickTarget != null">
					<div class="col-12">
						<p>
							{{ airSpaceClickTarget.options.information }}
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 mt-3">
						<p>{{ $t('flyingView.goTo') }}:
							<!--              <a v-on:click="goToThisPoint" href="javascript:void(0)" class="text-center">-->
							<!--                <font-awesome-icon class="m-auto" icon="location-arrow"/>-->
							<!--                {{ clickCoordinate.lat | formatDMS }} {{ clickCoordinate.lng | formatDMS('lng') }}-->
							<!--              </a>-->
						</p>
						<Listbox v-model="selectedObject" :options="objectsInCircle" optionLabel="title" @change="hideAirSpaceInfo"
							style="border: 2px solid #000000;">
							<template #option="slotProps">
								<div v-if="slotProps.option.distance > 0">
									<span>
										{{ $t('objectType.' + slotProps.option.type) }}
										<span v-if="slotProps.option.frequency > 0">({{slotProps.option.frequency }}) </span>
										<strong>|</strong>
										{{ slotProps.option.title }} ({{slotProps.option.code  }}) => {{ slotProps.option.distance | formatNumber }} {{ distanceUnit | unitSuffix }}
									</span>
								</div>
								<div v-if="slotProps.option.distance === 0">
									<span v-if="slotProps.option.type === 'AIRPORT'">
										{{ slotProps.option.code }} ({{slotProps.option.name}})
									</span>
									<span v-else>{{ slotProps.option.title }}</span>
								</div>
							</template>
						</Listbox>
					</div>
				</div>
				<div v-if="tooltip" v-html="tooltip" style="max-height: 200px; overflow-y: scroll" class="mt-3 border p-3" />
			</div>
		</modal>

		<Map ref="map" @mapClick="airSpaceClick" @clickedOnGpsMarker="clickedOnGpsMarker" @directTo="onMapDirectTo" />


		<div class="right-bottom">
			<button class="route-plan-btn" @click="selectRoutePlan">
				<font-awesome-icon icon="route" />
			</button>
			<div class="airspaces-updated-at" v-if="lastAirSpaceUpdate != null">
				<div v-if="showOldAirspaces" >
					<font-awesome-icon icon="exclamation-triangle" class="text-danger" @click="showOldAirspacesModal=true" style="cursor: pointer" />
				</div>
				<div>
					<h6>
						<font-awesome-icon icon="sync" style="cursor: pointer" @click="refreshAirspaces" />
						{{ $t('flyingView.airspacesUpdated') }}:
					</h6>
					<span>{{ lastAirSpaceUpdate }}</span>
				</div>
			</div>
			<div class="right-bottom-actions">
				<button class="mx-auto btn btn-dark lock-button" @click="lockScreen()">
					<font-awesome-icon class="mr-0" icon="lock" />
				</button>

				<button class="mx-auto btn btn-danger stop-flight-button" @click="stopFlight()">
					<font-awesome-icon class="mr-2" icon="stop" />
					{{ $t('flyingView.stop') }}
				</button>
			</div>
		</div>




		<div class="gps-permission-message-box" v-if="!disableGpsPermissionWarning && shouldShowGpsPermissionWarning">
			<div class="gps-permission-message">
				<div class="icon-box">
					<font-awesome-icon icon="exclamation-triangle" />
				</div>
				<div class="text-box">
					<h5>{{ $t('flyingView.gpsPermissionError') }}</h5>
					<p>{{ $t('flyingView.gpsPermissionErrorText') }}</p>
				</div>
				<div class="action-box">
					<button class="btn btn-primary btn-sm" @click="openPermissions">
						{{ $t('flyingView.openPermissions') }}
						<font-awesome-icon class="ml-2" icon="external-link-alt" />
					</button>
					<button class="btn btn-dark btn-sm" @click="disableGpsPermissionWarning = true">
						{{ $t('flyingView.dismiss') }}
						<font-awesome-icon class="ml-2" icon="times" />
					</button>
				</div>
			</div>
		</div>


		<div class="lock-screen" v-if="showLockScreen">
			<div>
				<logo :onlyIcon="true" :height="'7rem'" class="lock-icon" />
				<h5 v-html="$t('flyingView.enterPin', { ping: lockScreenPincode })"></h5>
				<!-- <h1>{{lockScreenPincode}}</h1> -->
				<div class="pin-input-circles">
					<div v-for="i in lockScreenPincode.length" :key="i"
						:class="`pin-input-circle ${i <= lockScreenInput.length ? '' : 'empty'}`">
					</div>
				</div>


				<div class="numpad">
					<div class="numpad-row">
						<div class="numpad-button" @click="onPinInput('1')">1</div>
						<div class="numpad-button" @click="onPinInput('2')">2</div>
						<div class="numpad-button" @click="onPinInput('3')">3</div>
					</div>
					<div class="numpad-row">
						<div class="numpad-button" @click="onPinInput('4')">4</div>
						<div class="numpad-button" @click="onPinInput('5')">5</div>
						<div class="numpad-button" @click="onPinInput('6')">6</div>
					</div>
					<div class="numpad-row">
						<div class="numpad-button" @click="onPinInput('7')">7</div>
						<div class="numpad-button" @click="onPinInput('8')">8</div>
						<div class="numpad-button" @click="onPinInput('9')">9</div>
					</div>
					<div class="numpad-row">
						<!-- <div class="numpad-button" @click="onPinInput('backspace')"><font-awesome-icon icon="backspace" /></div> -->
						<div class="numpad-button" @click="onPinInput('0')">0</div>
						<!-- <div class="numpad-button" @click="onPinInput('enter')"><font-awesome-icon icon="check" /></div> -->
					</div>
				</div>

			</div>
		</div>
		<Dialog :visible.sync="showOldAirspacesModal">
			<template #header>
				<div class="d-flex gap-2 align-items-center">
					<font-awesome-icon icon="exclamation-triangle" class="text-warning m-0" />
					<h5 class="font-weight-bold m-0">{{ $t('flyingView.oldAirspaces') }}</h5>
				</div>
			</template>
			<div class="">
				<p>{{ $t('flyingView.oldAirspacesText') }}</p>
				<h6 class="font-weight-bold">
					{{ $t('flyingView.updatedAt') }}
				</h6>
				<p>{{ lastAirSpaceUpdate }}</p>
				<div class="d-flex justify-content-end">
					<button class="btn btn-primary" @click="showOldAirspacesModal = false;refreshAirspaces()">
						<font-awesome-icon icon="check" />
						{{ $t('ok') }}
					</button>
				</div>
			</div>
		</Dialog>

	</div>
</template>

<script>
import Map from '@/components/map/Map.vue'
import Listbox from 'primevue/listbox';
import {
	HELPER_FUNCTIONS,
	LOCAL_STORAGE_SERVICE,
	READ_JSON_FILE,
	ROUTE_PLAN_CALCULATOR,
	STORAGE_KEY, TRACK_CEIL,
} from "@/plugins/utils";
import Logo from '@/components/Logo.vue';
import Dialog from 'primevue/dialog';
import DirectToModal from "@/components/map/DirectToModal.vue";
import RoutePlanSelectModal from "@/components/map/RoutePlanSelectModal.vue";


export default {
	name: 'FlyingView',
	components: {  Map, Logo, Listbox, Dialog },
	data() {
		return {
			registrationNumber: null,
			beaconType: null,
			selectedRoutePlan: null,
			airSpaceClickTarget: null,
			targetDMS: null,
			clickCoordinate: { lat: 0, lng: 0 },
			routeId: 0,
			routeIdWithUuid: null,
			objectsInCircle: [],
			selectedObject: null,
			/** @type {DistanceUnitType} */
			distanceUnit: this.$units.distance.km.key,
			routeChanges: [],
			mapObjects: [],
			demoTimer: null,
			tooltip: null,
			showLockScreen: false,
			lockScreenPincode: String((new Date()).getFullYear()),
			lockScreenInput: '',
			disableGpsPermissionWarning: false,
			airspacesUpdateAtInterval: null,
			showOldAirspacesModal: false,
			showOldAirspaces: false,
			oldAirspaceModalShown: null,
		};
	},
	props: {
		app: Object
	},
	async created() {
		this.registrationNumber = this.$route.params.registrationNumber;
		this.beaconType = this.$route.query.beaconType;
		this.routeIdWithUuid = this.$route.params.routeIdUUID;
		if (this.routeIdWithUuid) {
			const routeIdUUID = this.routeIdWithUuid.split('-');
			this.routeId = routeIdUUID[0];
		}
		this.routePlan = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.SELECTED_ROUTE_PLANE, null);
		this.mapObjects = await this.$rest.getAllObjects();
	},
	methods: {
		selectRoutePlan() {
			this.$modal.show(RoutePlanSelectModal, {}, {
				height: 'auto',
				clickToClose: true,
				adaptive: true,
			}, {
				'before-close': ({ params: rp }) => {
					if(!rp) return;
					this.routePlan = rp;
					LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.SELECTED_ROUTE_PLANE, rp)
					this.initMapDrawAndElements()
				}
			});
		},
		onMapDirectTo(selectObject){
			this.selectedObject = selectObject;
			this.goToThisPoint();
		},
		openPermissions() {
			window.loadAppLink('r2gflightserver://open-permissions');
		},
		goToThisPoint() {
			if (!this.selectedObject) {
				return;
			}
			this.$refs.map.clearDirectToPolyLines();

			const trueTrack = ROUTE_PLAN_CALCULATOR.TRUE_TRACK(this.$refs.map.getCurrentCoordinate().lat, this.$refs.map.getCurrentCoordinate().lng, this.selectedObject.latLng.lat, this.selectedObject.latLng.lng)
			const magneticTrack = ROUTE_PLAN_CALCULATOR.MAGNETIC_TRACK(trueTrack, ROUTE_PLAN_CALCULATOR.DEFAULT_DECLINATION);

			this.$refs.map.drawLineFromCurrentGpsTo([this.selectedObject.latLng.lat, this.selectedObject.latLng.lng], TRACK_CEIL(magneticTrack).toString());

			/** @type {RoutePlanChange} */
			const routeChange = {
				latitude: this.selectedObject.latLng.lat,
				longitude: this.selectedObject.latLng.lng,
				currentLatitude: this.$refs.map.getCurrentCoordinate().lat,
				currentLongitude: this.$refs.map.getCurrentCoordinate().lng,
				timestampOfChanges: HELPER_FUNCTIONS.backEndDateTimeFormat(new Date()),
				actualPlane: this.registrationNumber,
			}
			this.routeChanges.push(routeChange);
			this.appendAndSaveRouteChange(routeChange);
			this.$refs.map.setDirectToObject(this.selectedObject);
		},
		airSpaceClick(event, tooltip) {
			if (event.target) {
				this.airSpaceClickTarget = event.target;
				this.clickCoordinate = event.latlng;
			} else {
				this.clickCoordinate = event;
			}
			this.showAirSpaceInfo(tooltip);
		},
		showAirSpaceInfo(tooltip) {
			let onCircleObject = this.mapObjects.filter(obj => {
				obj.distance = ROUTE_PLAN_CALCULATOR.DISTANCE("km", this.clickCoordinate.lat, this.clickCoordinate.lng, obj.latitude, obj.longitude);
				if (obj.distance <= 5) {
					obj.latLng = { lat: obj.latitude, lng: obj.longitude };
					obj.title = obj.name;
					return true;
				}
				return false;
			});
			console.log(onCircleObject);

			onCircleObject = onCircleObject.sort((a, b) => {
				return a.distance - b.distance;
			});

			let poiName = ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LAT(this.clickCoordinate.lat) + " " + ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LNG(this.clickCoordinate.lng);
			if (onCircleObject.length > 0) {
				poiName = onCircleObject[0].name;
			}

			onCircleObject.unshift({ latLng: this.clickCoordinate, name: poiName, distance: 0, title: this.$t('routePlan.click-location') });
			this.objectsInCircle = onCircleObject;
			this.tooltip = tooltip;
			this.$modal.show('airSpaceInfo');
		},
		hideAirSpaceInfo() {
			this.goToThisPoint();
			this.$modal.hide('airSpaceInfo');
			this.tooltip = null;
		},
		setMapCenter() {
			setTimeout(() => {
				this.$refs.map.setRoutePlanCenter([this.routePlan.routePlanElements[0].startLatitude, this.routePlan.routePlanElements[0].startLongitude], 11)
			}, 500);
		},
		initMapDrawAndElements() {
			this.$refs.map.clearPlanElements();
			this.routePlan.routePlanElements.forEach((element, index) => {
				this.totalDistance += element.distance;
				this.totalFlightTime += element.flightTime;

				const startLatLng = HELPER_FUNCTIONS.getStartLatLong(element);
				const endLatLng = HELPER_FUNCTIONS.getEndLatLong(element);

				if (index === 0) {
					this.$refs.map.drawMarker(startLatLng, true, {
						title:this.routePlan.startElementTitle
					});
				}
				this.$refs.map.drawMarker(endLatLng, false, {
					title: element.title,
				});
				this.$refs.map.drawLine([startLatLng, endLatLng], '#00ffe1', TRACK_CEIL(element.magneticTrack).toString());
			});
		},
		/**
		 *
		 * @param {RoutePlanChange} routeChange
		 * @returns {Promise<void>}
		 */
		async appendAndSaveRouteChange(routeChange) {
			if (!this.routeId) return;
			const route = await this.$rest.appendRouteChanges(this.routeId, [routeChange]);
			if(route) return;

			// Could not save to server, save to local storage
			const directToInStorage = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.DIRECT_TO_KEY, {});
			directToInStorage[this.routeId] = directToInStorage[this.routeId] || [];
			directToInStorage[this.routeId].push(routeChange);
			LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.DIRECT_TO_KEY, directToInStorage);
		},
		async saveRouteChanges() {
			if (!this.routeId) return;

			const route = await this.$rest.replaceRouteChanges(this.routeId, this.routeChanges);
			if(route) return;

			// Could not save to server, save to local storage
			const directToInStorage = LOCAL_STORAGE_SERVICE.getItem(STORAGE_KEY.DIRECT_TO_KEY, {});
			directToInStorage[this.routeId] = this.routeChanges;
			LOCAL_STORAGE_SERVICE.setItem(STORAGE_KEY.DIRECT_TO_KEY, directToInStorage);
		},
		async stopFlight() {
			if (await this.$confirm(this.$t('flyingView.are-u-sure'))) {
				await this.stopFlightSimple(true);
			}
		},
		async stopFlightSimple(callBackServer) {
			let routeIdWithUuid = window.consentedStorage.getItem('currentRouteIdWithUuid');
			let index = routeIdWithUuid.indexOf('-');
			let routeId;
			if (index > 0) {
				routeId = parseInt(routeIdWithUuid.substring(0, index));
			}
			else {
				routeId = parseInt(routeIdWithUuid);
			}

			window.consentedStorage.removeItem('currentRegistrationNumber');
			window.consentedStorage.removeItem('currentRouteStart');
			window.consentedStorage.removeItem('currentRouteIdWithUuid');

			let currentRouteIsWithMikrotik = window.consentedStorage.getItem('currentRouteIsWithMikrotik');
			if (currentRouteIsWithMikrotik == 'true') {
				window.consentedStorage.removeItem('currentRouteIsWithMikrotik');
				let builtInMode = await this.$mikrotik.getEnvironmentVariable('r2gfsBuiltIn') === '1';
				if (!builtInMode) {
					await this.$mikrotik.disableScheduler('r2gfsGps');
				}
				await this.$mikrotik.setEnvironmentVariable('r2gfsRouteIdWithUuid', '');
				await this.$mikrotik.setEnvironmentVariable('r2gfsFlightId', '');
			}

			await this.$rest.stopRoute(routeId);

			window.loadAppLink('r2gflightserver://stop-route');
			window.loadAppLink('r2gflightserver://stop-mikrotik-gps-forward');
			this.$router.replace({ path: '/start-flight' });
		},
		clickedOnGpsMarker(marker) {
			window.loadAppLink(`r2gflightserver://get-coordinates?route-id=${this.routeId}&route-id-uuid=${this.routeIdWithUuid}`);
			// if (this.$refs.map.currentGpsMarker && this.$refs.map.currentGpsMarker.polyline) {
			// 	this.$refs.map.removeRoute(this.routeId);
			// 	this.$refs.map.currentGpsMarker.polyline = null;
			// }
			// else {
			//
			// }
		},
		startDemo() {
			let demo = [
				[47.31621203, 19.0700683, 100.5, 201.5, 6.379, '2020-10-05T12:45:51.000Z'],
				[47.3161355, 19.07004435, 99.2, 198.1, 8.077, '2020-10-05T12:45:52.000Z'],
				[47.31606523, 19.07000185, 99, 200.2, 8.334, '2020-10-05T12:45:53.000Z'],
				[47.31598528, 19.06996082, 99.7, 199.7, 10.032, '2020-10-05T12:45:54.000Z'],
				[47.31588345, 19.0699161, 99.2, 199.2, 11.884, '2020-10-05T12:45:55.000Z'],
				[47.31577263, 19.06985578, 99.6, 199.8, 13.787, '2020-10-05T12:45:56.000Z'],
				[47.31565223, 19.06979682, 100.7, 199, 15.691, '2020-10-05T12:45:57.000Z'],
				[47.31551615, 19.06972743, 101.2, 199.6, 16.411, '2020-10-05T12:45:58.000Z'],
				[47.31537083, 19.06965115, 101.1, 198.6, 17.028, '2020-10-05T12:45:59.000Z'],
				[47.3152179, 19.06959162, 101.8, 197.4, 18.211, '2020-10-05T12:46:00.000Z'],
				[47.31505508, 19.06951248, 101.6, 197.6, 19.24, '2020-10-05T12:46:01.000Z'],
				[47.31488122, 19.0694276, 101.3, 197.6, 20.166, '2020-10-05T12:46:02.000Z'],
				[47.3146973, 19.0693368, 100.2, 197.3, 21.195, '2020-10-05T12:46:03.000Z'],
				[47.31450637, 19.06926555, 100.7, 196.5, 21.967, '2020-10-05T12:46:04.000Z'],
				[47.31431655, 19.06919095, 101.2, 196.1, 21.761, '2020-10-05T12:46:05.000Z'],
				[47.3141247, 19.06910468, 100.8, 196.3, 21.761, '2020-10-05T12:46:06.000Z'],
				[47.31393262, 19.06901513, 99.8, 196.4, 22.018, '2020-10-05T12:46:07.000Z'],
				[47.3137457, 19.0689311, 100.1, 196.3, 22.327, '2020-10-05T12:46:08.000Z'],
				[47.31354557, 19.06884942, 99.4, 196.9, 22.327, '2020-10-05T12:46:09.000Z'],
				[47.313356, 19.06875998, 100.3, 196.8, 22.327, '2020-10-05T12:46:10.000Z'],
				[47.31316733, 19.06866002, 101, 196.9, 22.327, '2020-10-05T12:46:11.000Z'],
				[47.3129778, 19.06857283, 101.2, 196.2, 21.967, '2020-10-05T12:46:12.000Z'],
				[47.31279977, 19.06848795, 101.7, 197, 21.452, '2020-10-05T12:46:13.000Z'],
				[47.31261662, 19.06840118, 101, 196.8, 21.298, '2020-10-05T12:46:14.000Z'],
				[47.31243562, 19.0683225, 100.9, 196.1, 21.349, '2020-10-05T12:46:15.000Z'],
				[47.31225192, 19.06823913, 100, 196.4, 21.298, '2020-10-05T12:46:16.000Z'],
				[47.31207237, 19.0681628, 99.8, 196.5, 21.555, '2020-10-05T12:46:17.000Z'],
				[47.31188648, 19.0680858, 100, 195.3, 21.452, '2020-10-05T12:46:18.000Z'],
				[47.31170298, 19.0680086, 99.5, 196, 21.092, '2020-10-05T12:46:19.000Z'],
				[47.31152148, 19.06793135, 99.8, 196.8, 21.247, '2020-10-05T12:46:20.000Z'],
				[47.31133593, 19.06784688, 100, 197.1, 21.504, '2020-10-05T12:46:21.000Z'],
				[47.31114652, 19.06775673, 100.1, 196.9, 21.812, '2020-10-05T12:46:22.000Z'],
				[47.31095828, 19.06767347, 99.8, 196.8, 21.967, '2020-10-05T12:46:23.000Z'],
				[47.31077387, 19.06758523, 100, 196.2, 22.173, '2020-10-05T12:46:24.000Z'],
				[47.3105877, 19.0674996, 100.3, 197, 22.07, '2020-10-05T12:46:25.000Z'],
				[47.31039438, 19.0674135, 101, 196.4, 21.967, '2020-10-05T12:46:26.000Z'],
				[47.31020508, 19.06734335, 100.9, 196.5, 21.401, '2020-10-05T12:46:27.000Z'],
				[47.31002342, 19.0672637, 101, 196, 21.144, '2020-10-05T12:46:28.000Z'],
				[47.30984202, 19.06718868, 100.1, 196.5, 20.732, '2020-10-05T12:46:29.000Z'],
				[47.30966692, 19.06711412, 101.4, 196.6, 19.909, '2020-10-05T12:46:30.000Z'],
				[47.30951153, 19.06703548, 101.5, 196.7, 18.314, '2020-10-05T12:46:31.000Z'],
				[47.30936485, 19.06697055, 102.4, 196.4, 16.154, '2020-10-05T12:46:32.000Z'],
				[47.30923868, 19.06691622, 102.7, 196.8, 13.993, '2020-10-05T12:46:33.000Z'],
				[47.30913317, 19.06687362, 103.4, 200.6, 11.112, '2020-10-05T12:46:34.000Z'],
				[47.30904515, 19.06682593, 102.1, 204, 8.231, '2020-10-05T12:46:35.000Z'],
				[47.3090016, 19.06679312, 103.3, 206.5, 4.99, '2020-10-05T12:46:36.000Z'],
				[47.30897715, 19.06678062, 103.2, 209.9, 2.315, '2020-10-05T12:46:37.000Z'],
				[47.30896803, 19.06676608, 104.5, 207.7, 1.801, '2020-10-05T12:46:38.000Z'],
				[47.30894742, 19.06674892, 103.7, 216, 3.241, '2020-10-05T12:46:39.000Z'],
				[47.3089203, 19.0667023, 104.1, 228.5, 4.939, '2020-10-05T12:46:40.000Z'],
				[47.30888818, 19.06664328, 104.1, 235.6, 5.813, '2020-10-05T12:46:41.000Z'],
				[47.3088531, 19.06658792, 104, 226.8, 5.968, '2020-10-05T12:46:42.000Z'],
				[47.30880812, 19.06654208, 104.5, 208.8, 6.636, '2020-10-05T12:46:43.000Z'],
				[47.30875295, 19.06652108, 104.9, 191.6, 6.688, '2020-10-05T12:46:44.000Z'],
				[47.308694, 19.06653095, 105.1, 165.2, 6.894, '2020-10-05T12:46:45.000Z'],
				[47.30864503, 19.06656655, 104.4, 145, 6.585, '2020-10-05T12:46:46.000Z'],
				[47.30861115, 19.06663997, 104, 121.3, 6.688, '2020-10-05T12:46:47.000Z'],
				[47.30859138, 19.06673005, 103.8, 96.4, 7.048, '2020-10-05T12:46:48.000Z'],
				[47.3085978, 19.06681935, 102.7, 74.3, 7.922, '2020-10-05T12:46:49.000Z'],
				[47.30863332, 19.06691747, 102.5, 62.6, 8.077, '2020-10-05T12:46:50.000Z'],
				[47.3086647, 19.06703247, 100.4, 67.2, 8.077, '2020-10-05T12:46:51.000Z'],
				[47.30868122, 19.06713778, 100.3, 81, 8.283, '2020-10-05T12:46:52.000Z'],
				[47.30869728, 19.0672781, 97.9, 91, 9.363, '2020-10-05T12:46:53.000Z'],
				[47.3086981, 19.06740663, 97.5, 92.2, 11.061, '2020-10-05T12:46:54.000Z'],
				[47.30869753, 19.0675673, 97.5, 92.5, 12.861, '2020-10-05T12:46:55.000Z'],
				[47.30868523, 19.06774313, 96.8, 94.3, 14.662, '2020-10-05T12:46:56.000Z'],
				[47.30867337, 19.0679564, 97.7, 95, 16.514, '2020-10-05T12:46:57.000Z'],
				[47.3086611, 19.06816762, 98, 94.5, 16.411, '2020-10-05T12:46:58.000Z'],
				[47.3086502, 19.06839095, 98.6, 94.7, 17.388, '2020-10-05T12:46:59.000Z'],
				[47.30863623, 19.06863527, 98.7, 95.6, 18.469, '2020-10-05T12:47:00.000Z'],
				[47.30862345, 19.06888913, 99.1, 95.2, 19.395, '2020-10-05T12:47:01.000Z'],
				[47.30861005, 19.06915463, 100.2, 95.3, 20.372, '2020-10-05T12:47:02.000Z'],
				[47.30859708, 19.0694285, 99.8, 94.5, 21.349, '2020-10-05T12:47:03.000Z'],
				[47.3085848, 19.06972117, 100.2, 94.5, 22.378, '2020-10-05T12:47:04.000Z'],
				[47.30856188, 19.07002003, 100.6, 94.6, 23.304, '2020-10-05T12:47:05.000Z'],
				[47.30854917, 19.070329, 101.8, 94.2, 22.841, '2020-10-05T12:47:06.000Z'],
				[47.30853245, 19.07063012, 102.7, 94, 23.201, '2020-10-05T12:47:07.000Z'],
				[47.3085147, 19.070943, 103.6, 93.3, 23.613, '2020-10-05T12:47:08.000Z'],
				[47.30851273, 19.07126528, 103, 92.9, 24.025, '2020-10-05T12:47:09.000Z'],
				[47.30850268, 19.0715849, 104.1, 92.4, 24.436, '2020-10-05T12:47:10.000Z'],
				[47.30849717, 19.0719115, 104.5, 92.1, 24.951, '2020-10-05T12:47:11.000Z'],
				[47.3084985, 19.07225262, 104.1, 91.7, 25.465, '2020-10-05T12:47:12.000Z'],
				[47.30848697, 19.07259, 104.9, 91.4, 25.928, '2020-10-05T12:47:13.000Z'],
				[47.30848582, 19.07293057, 104.7, 90.2, 26.082, '2020-10-05T12:47:14.000Z'],
				[47.30848972, 19.07328165, 104.9, 89.4, 25.516, '2020-10-05T12:47:15.000Z'],
				[47.30849283, 19.07361445, 105.1, 88.9, 25.414, '2020-10-05T12:47:16.000Z'],
				[47.30849493, 19.07395503, 104.8, 88, 25.516, '2020-10-05T12:47:17.000Z'],
				[47.30850453, 19.07429228, 105, 87.2, 25.671, '2020-10-05T12:47:18.000Z'],
				[47.30851877, 19.07462783, 104.5, 86.4, 25.516, '2020-10-05T12:47:19.000Z'],
				[47.3085425, 19.07496835, 105, 84.9, 25.825, '2020-10-05T12:47:20.000Z'],
				[47.30856083, 19.075314, 104.2, 83.8, 25.825, '2020-10-05T12:47:21.000Z'],
				[47.30858335, 19.07565907, 103.9, 82.7, 26.031, '2020-10-05T12:47:22.000Z'],
				[47.30861213, 19.07600493, 103.7, 82, 26.237, '2020-10-05T12:47:23.000Z'],
				[47.3086383, 19.07635843, 103.4, 82, 26.391, '2020-10-05T12:47:24.000Z'],
				[47.30866397, 19.07671412, 103.1, 80.5, 26.34, '2020-10-05T12:47:25.000Z'],
				[47.30870452, 19.07705525, 102.2, 79.1, 26.442, '2020-10-05T12:47:26.000Z'],
				[47.30875288, 19.07739707, 102.2, 78.2, 26.597, '2020-10-05T12:47:27.000Z'],
				[47.30880112, 19.07774247, 101.6, 77.5, 26.34, '2020-10-05T12:47:28.000Z'],
				[47.30885448, 19.07807858, 101.7, 77.2, 26.134, '2020-10-05T12:47:29.000Z'],
				[47.30890197, 19.0784168, 100.7, 76.9, 25.877, '2020-10-05T12:47:30.000Z'],
				[47.30895682, 19.078748, 100.2, 75.5, 25.877, '2020-10-05T12:47:31.000Z'],
				[47.3090253, 19.07907677, 100.1, 74.3, 25.825, '2020-10-05T12:47:32.000Z'],
				[47.30909188, 19.07939308, 99.8, 73.1, 25.516, '2020-10-05T12:47:33.000Z'],
				[47.3091669, 19.07972453, 99.2, 72, 25.671, '2020-10-05T12:47:34.000Z'],
				[47.30923977, 19.08004018, 99.2, 71, 25.362, '2020-10-05T12:47:35.000Z'],
				[47.3093032, 19.08034667, 97.3, 70.2, 25.002, '2020-10-05T12:47:36.000Z'],
				[47.30940045, 19.0806529, 96.3, 69.3, 24.59, '2020-10-05T12:47:37.000Z'],
				[47.30947867, 19.08094062, 96.3, 68.6, 24.127, '2020-10-05T12:47:38.000Z'],
				[47.30955987, 19.08123415, 96.3, 67.8, 23.716, '2020-10-05T12:47:39.000Z'],
				[47.30964415, 19.08152165, 96.6, 66.5, 23.613, '2020-10-05T12:47:40.000Z'],
				[47.30972602, 19.0818123, 97, 66.3, 23.87, '2020-10-05T12:47:41.000Z'],
				[47.30981183, 19.0821038, 97, 65.9, 24.025, '2020-10-05T12:47:42.000Z'],
				[47.3099027, 19.08238513, 96.7, 64.1, 24.025, '2020-10-05T12:47:43.000Z'],
				[47.31000387, 19.08265262, 96.1, 61.9, 23.819, '2020-10-05T12:47:44.000Z'],
				[47.31010743, 19.08292352, 96.8, 60.8, 23.459, '2020-10-05T12:47:45.000Z'],
				[47.31021033, 19.08319335, 97.3, 61.2, 23.047, '2020-10-05T12:47:46.000Z'],
				[47.31031105, 19.08345073, 98.4, 60, 22.687, '2020-10-05T12:47:47.000Z'],
				[47.3104143, 19.0837101, 98.8, 59.1, 22.378, '2020-10-05T12:47:48.000Z'],
				[47.31051713, 19.08395798, 98.5, 59.2, 21.967, '2020-10-05T12:47:49.000Z'],
				[47.31061555, 19.08420573, 99.2, 58.6, 21.658, '2020-10-05T12:47:50.000Z'],
				[47.31071487, 19.0844478, 99.4, 58.1, 21.71, '2020-10-05T12:47:51.000Z'],
				[47.31082128, 19.08468663, 99.4, 56.3, 21.812, '2020-10-05T12:47:52.000Z'],
				[47.31092057, 19.08492918, 99.3, 55.7, 21.607, '2020-10-05T12:47:53.000Z'],
				[47.31102912, 19.08516392, 98.8, 55.2, 22.018, '2020-10-05T12:47:54.000Z'],
				[47.31114732, 19.08540362, 99.2, 54.3, 22.224, '2020-10-05T12:47:55.000Z'],
				[47.3112631, 19.0856414, 98.1, 53.6, 22.687, '2020-10-05T12:47:56.000Z'],
				[47.31138848, 19.08588227, 97.9, 52.9, 22.996, '2020-10-05T12:47:57.000Z'],
				[47.31151743, 19.08612473, 97.5, 51.8, 23.253, '2020-10-05T12:47:58.000Z'],
				[47.3116512, 19.08636788, 97.4, 51.4, 23.407, '2020-10-05T12:47:59.000Z'],
				[47.31178032, 19.08660948, 97.4, 50.1, 23.51, '2020-10-05T12:48:00.000Z'],
				[47.311917, 19.08684903, 97.6, 49.6, 23.407, '2020-10-05T12:48:01.000Z'],
				[47.31205445, 19.08709785, 97.5, 48.7, 23.304, '2020-10-05T12:48:02.000Z'],
				[47.31219318, 19.0873247, 97.2, 47.5, 23.253, '2020-10-05T12:48:03.000Z'],
				[47.31233578, 19.08754948, 96.8, 46.4, 23.15, '2020-10-05T12:48:04.000Z'],
				[47.31248147, 19.08776395, 97, 46.1, 23.047, '2020-10-05T12:48:05.000Z'],
				[47.31264023, 19.08797833, 96.2, 45, 22.996, '2020-10-05T12:48:06.000Z'],
				[47.3127919, 19.08819247, 96.5, 44.6, 22.79, '2020-10-05T12:48:07.000Z'],
				[47.31293965, 19.08841247, 96.8, 43.9, 22.841, '2020-10-05T12:48:08.000Z'],
				[47.31309407, 19.08861893, 97, 43.4, 22.79, '2020-10-05T12:48:09.000Z'],
				[47.31324583, 19.08881852, 96.2, 42.8, 22.584, '2020-10-05T12:48:10.000Z'],
				[47.31339495, 19.08902445, 96.6, 41.3, 22.841, '2020-10-05T12:48:11.000Z'],
				[47.31355803, 19.08922503, 96.5, 40.9, 23.201, '2020-10-05T12:48:12.000Z'],
				[47.3137177, 19.0894275, 97.3, 39.7, 23.356, '2020-10-05T12:48:13.000Z'],
				[47.31388392, 19.08962603, 97.9, 38.9, 23.562, '2020-10-05T12:48:14.000Z'],
				[47.31405363, 19.08980805, 97, 37.8, 23.819, '2020-10-05T12:48:15.000Z'],
				[47.31422587, 19.09000312, 96.7, 36.8, 23.922, '2020-10-05T12:48:16.000Z'],
				[47.31440217, 19.09019932, 94.1, 35.7, 24.076, '2020-10-05T12:48:17.000Z'],
				[47.31456812, 19.09038123, 93.8, 35.2, 24.23, '2020-10-05T12:48:18.000Z'],
				[47.31475032, 19.09056698, 93.3, 35, 24.642, '2020-10-05T12:48:19.000Z'],
				[47.31494045, 19.09074877, 93.7, 33.9, 25.208, '2020-10-05T12:48:20.000Z'],
				[47.31513255, 19.09094288, 93.5, 32.9, 25.568, '2020-10-05T12:48:21.000Z'],
				[47.31532647, 19.09112423, 93.9, 32.1, 25.928, '2020-10-05T12:48:22.000Z'],
				[47.31551388, 19.09130167, 92.9, 31.3, 26.134, '2020-10-05T12:48:23.000Z'],
				[47.31571593, 19.0914766, 93.6, 30.1, 26.237, '2020-10-05T12:48:24.000Z'],
				[47.31592553, 19.09165435, 92.2, 29.2, 26.134, '2020-10-05T12:48:25.000Z'],
				[47.3161348, 19.09182257, 92.6, 28.3, 26.237, '2020-10-05T12:48:26.000Z'],
				[47.31634167, 19.09198252, 92.1, 27.6, 25.928, '2020-10-05T12:48:27.000Z'],
				[47.31655075, 19.09214128, 92.3, 27.5, 25.979, '2020-10-05T12:48:28.000Z'],
				[47.31674257, 19.09228958, 90.7, 27.6, 25.928, '2020-10-05T12:48:29.000Z'],
				[47.31695468, 19.09244372, 91.2, 27.3, 25.979, '2020-10-05T12:48:30.000Z'],
				[47.31716178, 19.09259593, 92.1, 26.8, 25.722, '2020-10-05T12:48:31.000Z'],
				[47.31736793, 19.09275018, 92.7, 27.1, 25.722, '2020-10-05T12:48:32.000Z'],
				[47.31757217, 19.09290465, 93.1, 27.3, 25.516, '2020-10-05T12:48:33.000Z'],
				[47.31777672, 19.09305922, 93.7, 27.4, 25.259, '2020-10-05T12:48:34.000Z'],
				[47.31796982, 19.09321195, 92.5, 26.9, 24.899, '2020-10-05T12:48:35.000Z'],
				[47.3181618, 19.09335148, 92.7, 26.7, 24.488, '2020-10-05T12:48:36.000Z'],
				[47.3183524, 19.09349785, 93.5, 27.3, 23.87, '2020-10-05T12:48:37.000Z'],
				[47.31854398, 19.09363702, 93.8, 27.3, 23.767, '2020-10-05T12:48:38.000Z'],
				[47.31873305, 19.0937813, 94, 27.3, 23.716, '2020-10-05T12:48:39.000Z'],
				[47.31892252, 19.09391692, 94, 26.5, 23.562, '2020-10-05T12:48:40.000Z'],
				[47.31911923, 19.09406355, 94.3, 27.1, 23.51, '2020-10-05T12:48:41.000Z'],
				[47.31930915, 19.09419755, 94.8, 26.8, 23.356, '2020-10-05T12:48:42.000Z'],
				[47.31949452, 19.09433475, 95.4, 27, 23.253, '2020-10-05T12:48:43.000Z'],
				[47.3196697, 19.09447103, 94.3, 27.1, 23.201, '2020-10-05T12:48:44.000Z'],
				[47.31985133, 19.09460827, 94.8, 26.8, 23.15, '2020-10-05T12:48:45.000Z'],
				[47.32004443, 19.09475547, 94.5, 26.9, 23.253, '2020-10-05T12:48:46.000Z'],
				[47.32023067, 19.0948897, 94.7, 27.2, 23.356, '2020-10-05T12:48:47.000Z'],
				[47.320411, 19.09503418, 93.7, 27.6, 23.459, '2020-10-05T12:48:48.000Z'],
				[47.32060055, 19.09517258, 94.5, 26.7, 23.664, '2020-10-05T12:48:49.000Z'],
				[47.32078992, 19.0953165, 95.4, 27, 23.767, '2020-10-05T12:48:50.000Z'],
				[47.32098182, 19.09546262, 94.9, 27.2, 23.716, '2020-10-05T12:48:51.000Z'],
				[47.32117427, 19.0956051, 94.3, 27.4, 23.716, '2020-10-05T12:48:52.000Z'],
				[47.3213677, 19.0957496, 94.1, 26.3, 24.076, '2020-10-05T12:48:53.000Z'],
				[47.32155832, 19.0958912, 94.9, 26.6, 23.716, '2020-10-05T12:48:54.000Z'],
				[47.32175042, 19.09603488, 95.1, 26.4, 23.87, '2020-10-05T12:48:55.000Z']
			];
			this.endDemo();
			let index = 0;
			this.demoTimer = setInterval(() => {
				let sor = demo[index++];
				if (index >= demo.length) {
					index = 0;
				}
				window.feedLocation(sor[0], sor[1], sor[2], sor[3], sor[4], sor[5]);
			}, 500);
		},
		endDemo() {
			if (this.demoTimer) {
				clearInterval(this.demoTimer);
				this.demoTimer = null;
			}
		},
		cancelCurrentRoute() {
			this.stopFlightSimple(false);
		},
		async refreshAirspaces() {
			await this.$refs.map.loadNewAirspaces();
			this.calcOldAirspace();
		},

		lockScreen() {
			this.showLockScreen = true;
			this.$refs.map.unregisterFromFlyingObjectUpdates();
		},
		onPinInput(num) {
			if (this.lockScreenInput.length < 4) {
				this.lockScreenInput += num;
			}
			if (this.lockScreenInput.length >= 4) {
				setTimeout(() => {
					if (this.lockScreenInput === this.lockScreenPincode) {
						this.showLockScreen = false;
						this.$refs.map.registerToFlyingObjectUpdates();
					}
					this.lockScreenInput = '';
				}, 100);
			}
		},

		padNumber(num) {
			return num.toString().padStart(2, "0");
		},
		calcOldAirspace() {
			const lastUpdateRaw = this.$store.getters.getAirspaceUpdatedAt
			if(!lastUpdateRaw) {
				return;
			}
			let updatedAt = parseInt(lastUpdateRaw);

			if(isNaN(updatedAt)) {
				updatedAt = new Date(lastUpdateRaw).getTime();
			}

			const now = new Date().getTime();
			const diff = now - updatedAt;

			if(diff > 1000 * 10 * 60) { // 10 minutes
				if(!this.showOldAirspaces) {
					this.showOldAirspacesModal = true;
					this.oldAirspaceModalShown = true;
					this.showOldAirspaces = true;
				}
			}else{
				this.showOldAirspaces = false;
			}
		}
	},
	mounted() {
		console.log("mounted", this.registrationNumbgeter);
		this.$refs.map.setShowCurrentGPS(true, this.registrationNumber, this.beaconType);
		if (this.routePlan != null && this.routePlan.routePlanElements.length > 0) {
			this.setMapCenter();
			this.initMapDrawAndElements();
		}

		this.$refs.map.openWebSocket(this.$rest,() => {
			this.$refs.map.registerToFlyingObjectUpdates();
		});

		if ('ios' !== window.platform && 'android' !== window.platform) {
			setTimeout(() => {
				if (typeof window.feedLocation === 'function') {
					this.startDemo();
				}
			}, 100);
		}

		window.cancelCurrentRoute = this.cancelCurrentRoute;

		this.airspacesUpdateAtInterval = setInterval(this.calcOldAirspace, 1000 * 10);
	},
	computed: {
		lastAirSpaceUpdate() {
			//If you update this, update the same in the map component
			const lastUpdateRaw = this.$store.getters.getAirspaceUpdatedAt
			if(!lastUpdateRaw) {
				return null;
			}

			if(isNaN(parseInt(lastUpdateRaw))) {
				return null;
			}

			return window.formatTime(parseInt(lastUpdateRaw));
		},


		shouldShowGpsPermissionWarning() {
			const perm = this.$store.state.gpsPermission
			return perm != null && perm !== 'BACKGROUND';
		},
	},
	beforeDestroy() {
		this.endDemo();
		window.cancelCurrentRoute = null;
	}
}
</script>

<style>
.map-row {
	flex-grow: 1;
	position: relative;
	height: calc(100vh - 100px);
}

.right-bottom {
	position: absolute;
	bottom: 20px;
	right: 10px;
	z-index: 900;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.airspaces-updated-at {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap:1rem;
	background: rgba(0, 0, 0, 0.162);
	backdrop-filter: blur(5px);
	padding: 0.3rem 0.5rem;
	margin-bottom: 10px;
	border-radius: 5px;
}
.airspaces-updated-at h6 {
	margin: 0;
	font-size: 0.8em;
	text-align: right;
}
.airspaces-updated-at span {
	font-weight: 900;
	display: block;
	font-size: 0.9em;
}
.right-bottom-actions {
	position: relative;
	display: flex;
	gap: 0.5rem
}

.stop-flight-button {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
}

.lock-button {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
}



.lock-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 1);
	/* backdrop-filter: blur(2px); */
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lock-screen>div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* backdrop-filter: blur(5px); */
	/* background-color: rgba(255, 255, 255, 0.3); */
	/* border-radius: 10px; */
	/* padding: 2rem 5rem; */

}

.lock-screen>div .lock-icon * {
	/* font-size: 100px; */
	/* fill: #0086FF; */
	/* stroke: #0086FF; */
}

.lock-screen>div h5 {
	color: #fff;
	margin-top: 2rem;
	margin-bottom: 1rem;
	text-align: center;
	font-weight: 400;
}

.lock-screen>div .pin-input-circles {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	margin-top: 1rem;
}

.lock-screen>div .pin-input-circles .pin-input-circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #0086FF;
	border: solid 1px #0086FF;
}

.lock-screen>div .pin-input-circles .pin-input-circle.empty {
	background-color: #000;
}

.lock-screen>div .numpad {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.lock-screen>div .numpad .numpad-row {
	display: flex;
	justify-content: center;
	align-items: center;
}


.lock-screen>div .numpad .numpad-row .numpad-button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	/* background-color: #0086FF; */
	border: solid 2px #0086FF;
	/* box-shadow: 0 0 4px rgba(0, 0, 0, 0.75); */
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
	cursor: pointer;
}




@media (hover: hover) {
	.lock-screen>div .numpad .numpad-row .numpad-button:hover {
		background-color: #0086FF;
	}
}
.lock-screen>div .numpad .numpad-row .numpad-button:active {
		background-color: #0086FF;
}



.gps-permission-message-box {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.gps-permission-message {
	background-color: rgba(255, 255, 255, 1);
	padding: 3rem;
	border-radius: 5px;
	color: black;
	font-size: 0.9em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
	border: solid 1px rgba(0, 0, 0, 0.35);
}

.gps-permission-message .icon-box {
	font-size: 3em;
	display: flex;
	justify-content: center;
	color: #F0AD4E;
}

.gps-permission-message .text-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.gps-permission-message .text-box h5 {
	margin: 0;
}

.gps-permission-message .text-box p {
	margin: 0;
}

.gps-permission-message .action-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
}

@media screen  and (max-width: 400px){
	.gps-permission-message {
		padding: 3rem 1rem !important;
	}
	.gps-permission-message .icon-box {
		font-size: 2em;
	}
	.gps-permission-message .text-box h5 {
		font-size: 1.2em;
	}
	.gps-permission-message .text-box p {
		font-size: 0.8em;
	}
	.gps-permission-message .action-box {
		flex-direction: column;
	}

	.gps-permission-message .action-box button {
		width: 100%;
		font-size: 0.8em !important;
	}
}

.route-plan-btn {
	width: 60px;
	height: 60px;
	z-index: 901;
	border-radius: 0.5rem;
	border: 3px solid #000000;
	font-size: 1.5rem;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	color: #000000;
	transition: 0.1s scale ease-in-out;
	margin-bottom: 10px;
}

.route-plan-btn:active {
	transform: scale(1.03);
}

</style>
