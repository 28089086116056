<template>
	<div class="item-con">
		<div class="item" @click.stop="selectItem" :class="{
			'small': small,
		}">
			<div class="item-preview" v-if="preview">
				<img :src="preview" alt="preview" />
			</div>

			<div class="item-body">
				<div class="item-title">
					{{rp.name || '-'}}
				</div>

				<div class="item-date">
					<span class="utc">UTC</span>
					<span v-if="rp.modifiedAt">{{rp.modifiedAt | date('YYYY.MM.DD')}}</span>
					<span v-else>{{rp.createdAt | date('YYYY.MM.DD')}}</span>
					<div class="item-time">
						<div>
							<span v-if="rp.modifiedAt">{{rp.modifiedAt | date('HH:mm')}}</span>
							<span v-else>{{rp.createdAt | date('HH:mm')}}</span>
						</div>
					</div>
				</div>

				<div class="item-plane">
					<img :src="image" alt="plane" />
					<div>
						<span>{{rp.planeRegistrationNumber}}</span>
					</div>
				</div>

			</div>



			<div class="item-actions" v-if="!disableActions">
				<share-button type="ROUTE_PLAN" :shared-id="String(rp.id)" size="md" />
				<button class="btn btn-primary" @click="selectItem">
					<font-awesome-icon icon="eye" class="m-0 align-self-end" />
				</button>
				<DropdownButton btn-class="btn p-button-danger p-button-outlined">
					<template v-slot:button>
							<font-awesome-icon icon="ellipsis-v" class="m-0 align-self-end" />
					</template>
					<template v-slot:default>
						<div class="d-flex flex-column gap-2 ">
							<button  @click="$emit('remove', rp)" class=" btn border text-danger text-left">
								<font-awesome-icon class="mr-2" icon="trash"/>
								{{$t('routePlan.remove')}}
							</button>
							<button @click="$emit('copy', rp)" class="btn border text-warning text-left">
								<font-awesome-icon class="mr-2" icon="copy"/>
								{{$t('routePlan.copy')}}
							</button>
						</div>
					</template>
				</DropdownButton>
			</div>

		</div>
		<div class="item-mobile" @click="selectItem" :class="{
			'small': small,
		}">
			<div class="item-preview" v-if="preview">
				<img :src="preview" alt="preview" />
			</div>

			<div class="item-title">
				{{rp.name || '-'}}
			</div>

			<div class="item-body">
				<div class="item-plane">
					<img :src="image" alt="plane" />
					<div>
						<span>{{rp.planeRegistrationNumber}}</span>
					</div>
				</div>

				<div class="item-date">
					<span class="utc">UTC</span>
					<span v-if="rp.modifiedAt">{{rp.modifiedAt | date('YYYY.MM.DD')}}</span>
					<span v-else>{{rp.createdAt | date('YYYY.MM.DD')}}</span>
					<div class="item-time">
						<div>
							<span v-if="rp.modifiedAt">{{rp.modifiedAt | date('HH:mm')}}</span>
							<span v-else>{{rp.createdAt | date('HH:mm')}}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="item-actions" v-if="!disableActions">
				<div class="d-flex flex-row gap-2 w-100 ">
					<button class="btn btn-primary w-100" @click="selectItem">
						<font-awesome-icon icon="eye" class="m-0 align-self-end" />
						{{$t('routeCard.view')}}
					</button>
				</div>

				<div class="d-flex  gap-2 ">
					<share-button type="ROUTE_PLAN" :shared-id="String(rp.id)" size="md" />
					<button @click="$emit('copy', rp)" class="btn btn-warning text-left">
						<font-awesome-icon class="mr-2" icon="copy"/>
						{{$t('routePlan.copy')}}
					</button>
					<button  @click="$emit('remove', rp)" class="btn btn-danger text-left">
						<font-awesome-icon class="mr-2" icon="trash"/>
						{{$t('routePlan.remove')}}
					</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { getPlaneIcon } from "@/plugins/planeUtils";
import ShareButton from "@/components/sharing/ShareButton.vue";
import DropdownButton from "@/components/DropdownButton.vue";



const PRE_UID = 'rp_';
export default {
	name: 'RoutePlanCard',
	components: { DropdownButton, ShareButton },
	emits: ['update', 'copy', 'remove', 'click'],
	props: {
		routePlan: {
			type: Object,
			required: true
		},
		small: Boolean,
		disableActions: Boolean,
	},
	data() {
		return {
			/** @type {RoutePlan} */
			rp: null,
			preview: null,
		}
	},
	methods: {
		async setPreview() {
			if(!this.rp.hasPreview) {
				this.preview = null;
				return;
			}

			const imgUrl = `${this.$rest.baseUrl}/route-plan/${this.rp.id}/preview`;
			const http = new XMLHttpRequest();

			http.open('HEAD', imgUrl, false);
			http.send();


			if(http.status === 404) {
				this.preview = null;
				return;
			}
			this.preview = imgUrl;
		},
		selectItem() {
			this.$emit('click');
			if(this.disableActions) return;
			this.$router.push({path: '/route-planning-element/' + this.rp.id});
		},
	},
	computed: {
		image() {
			return getPlaneIcon(this.rp.beaconType)
		}
	},
	created() {
		this.rp = this.routePlan;
		this.setPreview();
	}
}
</script>

<style scoped lang="css" >


.item-con{
	width: 100%;
}

.item {
	display: flex;;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #f8f9fa;
	border-radius: 1rem;
	width: 100%;
	padding: 1rem 2rem;
	gap: 2rem;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	cursor: pointer;
}


.item.small {
	padding: 0.5rem 1rem;
	gap: 1rem;
	border-radius: 0.5rem;
}

.item:hover {
	background-color: #f4f6f7;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.item-date {
	font-size: 1.3rem;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.item.small .item-date {
	font-size: 0.9rem;
}

.item-date .utc {
	font-size: 0.6rem;
	font-weight: 400;
	color: #6c757d;
}

.item.small .item-date .utc {
	font-size: 0.6rem;
}

.item-plane {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.item.small .item-plane {
	gap: 0.3rem;
}


.item-plane img {
	width: 2.1rem;
	transform: rotate(45deg);
	flex-shrink: 1;
}

.item.small .item-plane img {
	width: 1.5rem;
}

.item-plane div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.item-plane div span {
	font-size: 1rem;
	font-weight: 500;
	overflow: hidden;
	white-space: pre-wrap;
	text-overflow: ellipsis;
	line-height: 1.5em;
	max-height: 3em;
	word-break: break-word;
}

.item.small .item-plane div span {
	font-size: 0.8rem;
}



.item-preview {
	flex-shrink: 0;
	width: 100px;
	border-radius: 0.5rem;
	overflow: hidden;
}

.item.small .item-preview {
	width: 50px;
}


.item-preview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.item-mobile{
	display: none;
}

.item-actions {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
}

.item .item-body {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	flex: 1;
}


.item-title {
	font-size: 1.2rem;
	font-weight: 700;
	margin: 0 1rem;
}

.item.small .item-title {
	font-size: 1rem;
}

@media screen and (max-width: 1024px) and (min-width: 800px) {

	.item-plane div span {
		font-size: 0.8rem;
		min-width: 85px;
		max-width: 85px;
	}

	.item-plane img {
		width: 1.8rem;
	}
	.item .item-date .item-time div img {
		width: 1rem;
	}
	.item .item-date .item-time div {
		font-size: 0.8rem;
	}
	.item .item-date .item-time {
		gap: 0.5rem;
	}
	.item .item-date {
		font-size: 1rem;
	}
	.item {
		padding: 1rem;
		gap: 0.5rem;
	}

	.item-preview {
		width: 60px;
	}
}

@media screen and (max-width: 800px) {

	.item-con {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item:not(.small) {
		display: none;
		visibility: hidden;
		opacity: 0;
	}


	.item-mobile:not(.small){
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		background-color: #f8f9fa;
		border-radius: 1rem;
		width: 100%;
		padding-bottom: 1rem;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
		cursor: pointer;
		overflow: hidden;
		max-width: 400px;
		gap: 1rem;
	}

	.item-mobile .item-body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		gap: 1.2rem;
		padding: 0 1rem;
		width: 100%;
	}

	.item-mobile .item-title {
		margin: 1.5rem 0 0 0;
	}


	.item-mobile .item-preview{
		width: 100%;
		border-radius: 0;
	}

	.item-mobile .item-actions {
		margin-top: 1rem;
		display: flex;
		flex-direction: column;
	}


	.item-mobile .item-plane div span {
		font-size: 1.1rem;
		max-width: unset;
	}

	.item-mobile .item-plane img {
		width: 2rem;
	}

}

@media screen and (max-width: 500px) {
	.item.small .item-preview {
		display: none;
	}

	.item.small .item-title {
		font-size: 0.7rem;
	}

	.item.small .item-plane div span {
		font-size: 0.7rem;
	}

	.item.small .item-plane img {
		width: 1.1rem;
	}

	.item.small .item-date {
		font-size: 0.7rem;
	}

	.item.small .item-date .utc {
		font-size: 0.4rem;
	}

	.item.small .item-date .item-time div {
		font-size: 0.5rem;
	}


	.item.small {
		gap: 0.3rem;
	}
	.item.small .item-body {
		gap: 0.5rem;
	}

}

@media screen and (max-width: 400px) {
	.item-body{
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

</style>
